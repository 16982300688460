@font-face {
  font-family: Frutiger_light;
  src: url(../font/FRUTL___.TTF) format("truetype");
}

@font-face {
  font-family: Frutiger_bold;
  src: url(../font/frutigerLB.ttf) format("truetype");
}

@font-face {
  font-family: Frutiger_Ultra_bold;
  src: url(../font/FRUTUBL_.TTF) format("truetype");
}

@font-face {
  font-family: mYuenLight;
  src: url(../font/MYuenHK-Light.otf) format("opentype");
}

.da_appendix {
  font-family: "Frutiger_light", Times, serif;
  width: 190%;
  height: 100%;
  box-sizing: border-box;
  font-size: 11px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  margin: "auto",
}

@media screen and (min-width: 2000px) {
  .da_appendix {
    width: 80%;
    margin: "auto",
  }
}

.da_appendixContainer {
  padding: 48px 25.64px 48px 50.44px; 
  /* margin: 0px 50px 0px 0px; */
  margin-left: 50px;
  line-height: 1.6;
}

.da_appendix input {
  border: 0px;
  width: 100%;
  font-family: "Frutiger_light", Times, serif;
  font-size: 11px;
}

.da_manufacturer_code {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-family: "Frutiger_light", Times, serif;
}

.da_manufacturer_name {
  width: 33% !important;
}

.da_appendix .da_manufacturer_address {
  width: 50%;
}

.da_appendixHeader {
  display: flex;
  justify-content: space-between;
  font-family: "Frutiger_Ultra_bold", Times, serif;
}

.da_appendixHeader p {
  font-family: "Frutiger_Ultra_bold", Times, serif;
}

.da_appendixSection {
  padding: 10px;
  text-align: center;
  font-family: "Frutiger_Ultra_bold", Times, serif;
  margin-top: 25px;
}

.da_appendixSection p {
  font-family: "Frutiger_Ultra_bold", Times, serif;
}

.da_appendixHeader2 {
  text-align: start;
  padding: 10px 0px;
  font-weight: bold;
  text-decoration: underline;
  font-family: "Frutiger_light", Times, serif;
}

.da_appendixHeader3 {
  margin-right: 150px;
}

.da_tableHeader {
  background-color: #d3d3d3;
  font-weight: bold;
  font-family: "Frutiger_light", Times, serif;
  /* flex-direction: "row";
  align-items: "center"; */
}

.da_appendix table,
.da_appendix th,
.da_appendix td {
  border: 1px solid;
  border-collapse: collapse;
  font-family: "Frutiger_light", Times, serif;
}

.da_tableB {
  width: 90%;
}

.da_tableB td {
  padding-left: 5px;
}

.da_appendix .da_tableCmodel {
  width: 100%;
  border: 0px;
}

.da_appendix .da_tableCcomp {
  width: 100%;
  border: 0px;
}

.da_tableCcomp td,
.da_tableCmodel td,
.da_tableCcomp th,
.da_tableCmodel th {
  border: 0px;
  width: 33.3%;
  text-align: left;
}

.da_romanIndex {
  padding-top: 25px;
  font-weight: bold;
  text-decoration: underline;
  font-family: "Frutiger_light", Times, serif;
}

.da_tableCcomp thead td,
.da_tableCmodel thead {
  font-weight: bold;
}
