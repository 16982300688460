.appendixSC {
  font-family: "Frutiger_light", Times, serif;
  width: 55%;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 11px;
  overflow-y: scroll;
  background-color: white;
}

.appendixSC body {
  margin: 0px;
}
.appendixSC p {
  font-size: 11px;
  font-family: "Frutiger_light";
  margin: "0px";
}

.appendixSC table {
  font-size: 10px;
  font-family: "Frutiger_light";
  width: 100%;
  margin: 0px 3px 18px 0px;
}

.appendixSC table,
.appendixSC th,
.appendixSC td {
  border: 1px solid black;
  border-collapse: collapse;
}
.appendixSC th {
  font-family: Frutiger_bold;
}

.appendixSC td,
.appendixSC th {
  padding: 3px;
  text-align: center;
}

.appendixSC input {
  width: 100%;
  font-family: "Frutiger_light", Times, serif;
  font-size: 11px;
}

.appendixSC .tableHeader {
  text-align: left;
  background-color: #f1f1f1;
}
.appendixSC .manufacturer {
  text-align: left;
  font-size: 10px;
  margin-bottom: 5px;
}
.appendixSC .bold {
  font-family: "Frutiger_Ultra_bold";
}
.appendixSC .lightBold {
  font-family: "Frutiger_bold";
}
.appendixSC .underline {
  text-decoration: underline;
}
.appendixSC .header {
  font-size: 14px;
}
.appendixSC .caption {
  font-size: 9px;
}
