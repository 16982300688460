@tailwind base;
@tailwind components;
@tailwind utilities;


input {
    border: 1px solid black;
}

a {
    text-decoration:underline;
    color: rgba(31, 31, 194, 0.863);
}

.emsdFontBold_global {
    font-family: "Frutiger_ultra_bold";
    font-display: swap;
    src: url(./admin/styles/font/FRUTUBL_.TTF) format("TTF");
}

.emsdFontLight_global {
    font-family: "Frutiger_light";
    font-display: swap;
    src: url(./admin/styles/font/FRUTL___.TTF) format("TTF");
}

.emsdFontmedium_global {
    font-family: "Frutiger_bold";
    font-display: swap;
    src: url(./admin/styles/font/frutigerLB.ttf) format("TTF");
}

.emsdFontItalic_global {
    font-family: "Frutiger_italic";
    font-display: swap;
    src: url(./admin/styles/font/Frutiger-BoldItalic.otf) format("TTF");
}

.futura_global {
    font-family: "Futura";
    font-display: swap;
    src: url(./admin/styles/font/FuturaCondensedLightRegular.otf) format("TTF");
}

.deJaVu_global {
    font-family: "DejaVu Sans";
    font-display: swap;
    src: url(./admin/styles/font/DejaVuSans.ttf) format("TTF");
}

.mYuenLight_global {
    font-family: "mYuenLight";
    font-display: swap;
    src: url(./admin/styles/font/MYuenHK-Light.otf) format("TTF");
}

.NotoSansSC_Regular_global {
    font-family: "NotoSansSC_Regular";
    font-display: swap;
    src: url(./admin/styles/font/Noto_Sans_SC/static/NotoSansSC-Regular.ttf) format("TTF");
}

.NotoSansSC_Bold_global {
    font-family: "NotoSansSC_Bold";
    font-weight: bold;
    font-display: swap;
    src: url(./admin/styles/font/Noto_Sans_SC/static/NotoSansSC-Bold.ttf) format("TTF");
}

.NotoSansSC_Bold700_global {
    font-family: "NotoSansSC_Bold";
    font-weight: 700;
    font-display: swap;
    src: url(./admin/styles/font/Noto_Sans_SC/static/NotoSansSC-Bold.ttf) format("TTF");
}

.NotoSansSC_Bold400italic_global {
    font-family: "NotoSansSC_Bold";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url(./admin/styles/font/Noto_Sans_SC/static/NotoSansSC-Bold.ttf) format("TTF");
}

.NotoSansSC_Medium_global {
    font-family: "NotoSansSC_Medium";
    font-display: swap;
    src: url(./admin/styles/font/Noto_Sans_SC/static/NotoSansSC-Medium.ttf) format("TTF");
}

.NotoSansSC_Medium400italic_global {
    font-family: "NotoSansSC_Medium";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url(./admin/styles/font/Noto_Sans_SC/static/NotoSansSC-Medium.ttf) format("TTF");
}

.NotoSansSC_Light_global {
    font-family: "NotoSansSC_Light";
    font-display: swap;
    src: url(./admin/styles/font/Noto_Sans_SC/static/NotoSansSC-Light.ttf) format("TTF");
}

.NotoSansSC_Light400italic_global {
    font-family: "NotoSansSC_Light";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url(./admin/styles/font/Noto_Sans_SC/static/NotoSansSC-Light.ttf) format("TTF");
}

.MPLUSRounded1c_Regular_global {
    font-family: "MPLUSRounded1c_Regular";
    font-display: swap;
    src: url(./admin/styles/font/M_PLUS_Rounded_1c/MPLUSRounded1c-Regular.ttf) format("TTF");
}