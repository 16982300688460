.reports__outercontainer {
 margin-top: 100px;
} 

.reports__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 100vh; */
  min-width: 100vh;
  width: 480px;
  margin: 0 auto;
  padding: 2rem;
  /* text-align: center; */
  line-height: 1.5;
  font-weight: 400;
  font-size: 2em;
}

.reports__date {
  margin-left: 10px;
}

.reports__button {
  margin-top: 10px;
  text-align: center;
}

.reports__export {
  text-align: center;
  margin-top: 10px;
}

.reports__message {
  margin-top: 30px;
  font-size: 0.8em;
}