.logs__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* height: 100vh; */
    min-width: 100vh;
    width: 480px;
    margin: 0 auto;
    padding: 2rem;
    /* text-align: center; */
    line-height: 1.5;
    font-weight: 400;
    font-size: 2em;
  }

  .logs__date {
    margin-left: 10px;
  }

  .logs__button {
    margin-top: 10px;
    text-align: center;
  }

  .logs__export {
    text-align: center;
    margin-top: 10px;
  }

  .logs__message {
    margin-top: 30px;
    font-size: 0.8em;
  }