.dappendixSC {
  font-family: "Frutiger_light", Times, serif;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 11px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}

.dappendixSC body {
  margin: 0px;
}
.dappendixSC p {
  font-size: 11px;
  font-family: "Frutiger_light";
  margin: "0px";
}

.dappendixSC table {
  font-size: 10px;
  font-family: "Frutiger_light";
  width: 100%;
  margin: 0px 3px 18px 0px;
}

.dappendixSC table,
.dappendixSC th,
.dappendixSC td {
  border: 1px solid black;
  border-collapse: collapse;
}
.dappendixSC th {
  font-family: Frutiger_bold;
}

.dappendixSC td,
.dappendixSC th {
  padding: 3px;
  text-align: center;
}

.dappendixSC input {
  border: 0px;
  width: 100%;
  font-family: "Frutiger_light", Times, serif;
  font-size: 11px;
}

.dappendixSC .tableHeader {
  text-align: left;
  background-color: #f1f1f1;
}
.dappendixSC .manufacturer {
  text-align: left;
  font-size: 10px;
  margin-bottom: 5px;
}
.dappendixSC .bold {
  font-family: "Frutiger_Ultra_bold";
}
.dappendixSC .lightBold {
  font-family: "Frutiger_bold";
}
.dappendixSC .underline {
  text-decoration: underline;
}
.dappendixSC .header {
  font-size: 14px;
}
.dappendixSC .caption {
  font-size: 9px;
}
