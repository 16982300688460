/* styles.css */

/* Box Styles */
/* .overview_box {
  border: 1.5px solid #e0e0; 
  border-radius: 15px;
  box-shadow: 0 8px 10px #efeeee; 
} */

/* Typography */
.overview_overview {
  font-family: "Frutiger_bold";
  font-size: 1.2rem;
  padding-left: 30px;
}

.overview_primary {
  color: #2a598f; 
  font-family: "Frutiger_bold";
  font-size: 1.2rem;
  margin-bottom: 7px;
}

.overview_secondary {
  color: #5c5c5c; 
  font-size: 1.1rem;
}

.overview_countDescription {
  font-family: "Frutiger_bold";
}

.overview_number {
  font-family: "Frutiger_bold";
  font-size: 1.3rem;
  color: #2a598f; 
  padding-right: 5px;
}

.overview_statisticsHeader {
  display: flex;
  align-items: flex-end;
}

.overview_revokeHeader {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
}

/* Layout */
.overview_welcomeBox {
  display: flex;
  padding: 20px 50px;
  margin: 30px 0px;
  border: 1.5px solid #e0e0e0; 
  border-radius: 15px;
  box-shadow: 0 8px 10px #efeeee;
}

.overview_applicationCountContainer {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.overview_applicationCount {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px;
  flex: 1;
  border: 1.5px solid #e0e0e0; 
  border-radius: 15px;
  box-shadow: 0 8px 10px #efeeee; 
}

.overview_statisticsBox {
  padding: 20px 50px 10px 50px;
  margin: 30px 0px;
  border: 1.5px solid #e0e0; 
  border-radius: 15px;
  box-shadow: 0 8px 10px #efeeee; 
}

.overview_revokeBox {
  padding: 20px 50px 10px 50px;
  margin: 30px 0px;
  border: 1.5px solid #e0e0; 
  border-radius: 15px;
  box-shadow: 0 8px 10px #efeeee; 
}

.overview_statisticsArray {
  display: flex;
}

/* Count and Statistics */
.overview_count {
  font-size: 0.8rem;
  color: #5c5c5c; 
}

.overview_statistics {
  font-size: 0.8rem;
  color: #5c5c5c; 
  padding: 12.5px 30px;
}

.overview_revokeStatistics {
  font-size: 0.8rem;
  color: #5c5c5c; 
  padding: 12.5px 30px;
}

/* Revoke List */
.overview_revokeListUl {
  padding-left: 0px;
  font-size: 0.8em;
  color: #64748b; 
}

.overview_revokeListItem {
  font-size: 0.8em;
  margin-left: 20px;
}

/* .overview_listitem {
  font-size: 1.2rem;
} */


.overview_img {
  width: 50px;
}