.revoke__revocationLetter-btn {
  margin-bottom: 15px;
  border: 1px solid gray;
  background-color: rgb(233,233,237);
  color: rgb(36,36,37);
  border-radius: 3px;
  appearance: none;
  padding: 2px 5px;
  font-size: .75em;
}

.revoke__revocationLetter-downloadbtn {
  margin-bottom: 15px;
  display: flex;
  border: 1px solid gray;
  background-color: rgb(233,233,237);
  color: rgb(36,36,37);
  border-radius: 3px;
  appearance: none;
  padding: 2px 5px;
  font-size: .7em;
  width: 70px;
}

.revoke__revocationLetter-upload {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 15px;
  /* width: 60px; */
}

.revoke__revocationLetter-upload :first-child {
  width: 60px;
}

.revoke__revocationLetter-rc {
  width: 100px;
}

.revoke__revocationLetter-model {
  margin-top: 20px;
}


/* the below are the styles for the revocation letter */
.revoke * {
  box-sizing: border-box;
  font-family: Frutiger_light;
  font-size: 11px;
}

.revoke p {
  margin: 0;
}

.revoke .rl-column {
  display: flex;
  flex-direction: column;
}

.revoke .rl-row {
  display: flex;
  flex-direction: row;
}

.revoke .rl-bold,
.revoke .rl-bold * {
  font-family: Frutiger_bold;
}

.revoke .rl-logo {
  position: relative;
  width: 210px;
  left: 45%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.revoke .rl-address {
  margin-right: 100px;
  margin-bottom: 3px;
  align-self: flex-end;
}

.revoke .rl-address * {
  line-height: 1.1;
  font-size: 10px;
  font-family: mYuenLight;
}

.revoke .rl-address_eng {
  margin-right: 83px;
  align-self: flex-end;
  transform: scale(0.5, 1);
  transform-origin: right;
  line-height: 1.1;
}

.revoke .rl-letterHeader {
  justify-content: space-between;
  padding: 0px 52px 0 26px;
}

.revoke .rl-letterHeaderRight {
  flex-basis: 43%;
  padding-top: 10px;
}

.revoke .rl-reference {
  align-items: flex-start;
  margin-bottom: 5px;
}

.revoke .rl-thinFont {
  transform: scale(0.5, 1);
  transform-origin: right;
  margin-right: 8px;
}

.revoke .rl-chinese {
  font-family: mYuenLight;
  font-size: 10px;
  padding-top: 2px;
  line-height: 1.3;
}

.revoke .rl-sendAddress {
  margin-top: 10px;
  margin-bottom: 15px;
  line-height: 1.6;
}

.revoke .rl-phoneNumber * {
  margin-top: 3px;
}

.revoke .rl-facsimile {
  margin-left: 6px;
  margin-bottom: 0px;
}

.revoke .rl-fax {
  margin-top: 8px;
}

.revoke .rl-padding {
  padding: 0px 105px;
}

.revoke .rl-yourRef_padding {
  padding: 0px 105px;
}

.revoke .rl-date_padding {
  padding: 10px 105px;
}

.revoke .rl-content {
  line-height: 1.6;
}

.revoke .rl-title {
  font-family: "Frutiger_Ultra_bold";
  padding: 15px 0px ;
  border-bottom: 1px solid black;
}

.revoke .rl-title * {
  font-family: "Frutiger_Ultra_bold";
}

.revoke .rl-main {
  padding-top: 15px;
}

.revoke .rl-main p {
  margin-bottom: 15px;
}

.revoke .rl-sign {
  margin-left: 105px;
}

.revoke .rl-signature {
  width: 215px;
  padding: 30px 0px;
  border-bottom: 1px solid black;
  margin-bottom: 5px;
}

.revoke .revocationLetter {
  font-family: "Frutiger_light", Times, serif;
  box-sizing: border-box;
  font-size: 11px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  width: 80%;
  margin: 0 auto;
}

.revoke .rl-reference_no {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.revoke .rl-rcname {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 15px;
}

.revoke .rl-dear {
  margin-top: 4px;
}