.react-confirm-alert-body * {
  font-family: "Frutiger_bold";
}

.react-confirm-alert-body {
  font-family: "Frutiger_light";
}

.react-confirm-alert-body h1 {
  font-size: 1.45rem;
  margin-bottom: 10px;
}

.react-confirm-alert-button-group button {
  background-color: #2a598f;
}

.react-confirm-alert-body-overlay {
  z-index: 9999;
}
