.deAppendicesPage-container {
    margin: auto;
    height: 100%;
    /* center */
    position: relative;
    top: 3em;
}

.deAppendicesPage-m-r-1 {
    margin-right: 1em;
}
