.mobile__link {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
.mobile__link:hover {
  color: #535bf2;
}
.mobile__container h1 {
  font-size: 2.5em;
  line-height: 1.1;
}
.mobile__button {
  border-radius: 8px;
  border: 1px solid gray;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
}
.mobile__button a {
  text-decoration: none;
}
.mobile__button:hover {
  border-color: #646cff;
  background-color: hsl(0, 0%, 90%);
}
.mobile__button:focus,
.mobile__button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}
.mobile__logo {
  height: 4em;
}
.mobile__container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100vh;
  min-width: 320px;
  width: 480px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  line-height: 1.5;
  font-weight: 400;
  font-size: 2em;
}
.mobile__card {
  padding: 2em;
}
.mobile__qrcode {
  margin-top: 30px;
}
.mobile__logo {
  height: 80%;
  width: 80%;
}
@media (prefers-color-scheme: light) {
 .mobile__link:hover {
    color: #747bff;
  }
  .mobile__button {
    background-color: #f9f9f9;
  }
}