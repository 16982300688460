.quill .ql-editor * {
  font-family: "Frutiger_light";
  font-size: 0.85rem;
  line-height: 2;
}

.ql-readOnly * {
  border: none !important;
  outline: none !important;
}

.ql-display-msg-box {
  display: flex;
  height: 550px;
}

.ql-msg-box-input {
  width: 1000px;
  height: 400px;
  margin-bottom: 50px;
}