.al * {
  box-sizing: border-box;
  font-family: Frutiger_light;
  font-size: 11px;
}

.al p {
  margin: 0;
}

.al .al-column {
  display: flex;
  flex-direction: column;
}

.al .al-row {
  display: flex;
  flex-direction: row;
}

.al .al-bold,
.al .al-bold * {
  font-family: Frutiger_bold;
}

.al .al-logo {
  position: relative;
  width: 210px;
  left: 45%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.al .al-address {
  margin-right: 49px;
  margin-bottom: 3px;
  align-self: flex-end;
}

.al .al-address * {
  line-height: 1.1;
  font-size: 10px;
  font-family: mYuenLight;
}

.al .al-address_eng {
  margin-right: 32px;
  align-self: flex-end;
  transform: scale(0.5, 1);
  transform-origin: right;
  line-height: 1.1;
}

.al .al-letterHeader {
  justify-content: space-between;
  padding: 0px 52px 0 26px;
}

.al .al-letterHeaderRight {
  flex-basis: 43%;
  padding-top: 10px;
}

.al .al-reference {
  align-items: flex-start;
  margin-bottom: 5px;
}

.al .al-thinFont {
  transform: scale(0.5, 1);
  transform-origin: right;
  margin-right: 8px;
}

.al .al-chinese {
  font-family: mYuenLight;
  font-size: 10px;
  padding-top: 2px;
  line-height: 1.3;
}

.al .al-ml {
  margin-left: -4px;
}

.al .al-sendAddress {
  margin-top: 10px;
  margin-bottom: 15px;
  line-height: 1.6;
}

.al .al-phoneNumber * {
  margin-top: 8px;
}

.al .al-facsimile {
  margin-left: 6px;
  margin-bottom: 0px;
}

.al .al-padding {
  padding: 0px 105px;
}
.al .al-content {
  line-height: 1.6;
}
.al .al-title {
  font-family: "Frutiger_Ultra_bold";
  padding: 15px 0px;
  border-bottom: 1px solid black;
}

.al .al-title * {
  font-family: "Frutiger_Ultra_bold";
}

.al .al-main {
  padding-top: 15px;
}
.al .al-main p {
  margin-bottom: 15px;
}

.al .al-sign {
  margin-left: 105px;
}

.al .al-signature {
  width: 215px;
  padding: 30px 0px;
  border-bottom: 1px solid black;
  margin-bottom: 5px;
}
