@font-face {
  font-family: Frutiger_light;
  src: url(../font/FRUTL___.TTF) format("truetype");
}

@font-face {
  font-family: Frutiger_bold;
  src: url(../font/frutigerLB.ttf) format("truetype");
}

@font-face {
  font-family: Frutiger_Ultra_bold;
  src: url(../font/FRUTUBL_.TTF) format("truetype");
}

@font-face {
  font-family: mYuenLight;
  src: url(../font/MYuenHK-Light.otf) format("opentype");
}

@font-face {
  font-family: Frutiger_italic;
  src: url(../font/Frutiger-BoldItalic.otf) format("opentype");
}

@font-face {
  font-family: mHeiBold;
  src: url(../font/MHeiHK-Bold.otf) format("opentype");
}

.appendix {
  font-family: "Frutiger_light", Times, serif;
  width: 55%;
  box-sizing: border-box;
  font-size: 11px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  min-width: 450px;
}

.appendix_rm {
  width: 70%;
}

.cert {
  position: absolute;
  right: 0%;
  width: 650px;
  height: 919px;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  z-index: 2;
}

.PdfHighlighter::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}

.cert::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}

.appendix input {
  font-family: "Frutiger_light", Times, serif;
  font-size: 11px;
}

.manufacturer_code {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-family: "Frutiger_light", Times, serif;
}

.manufacturer_name {
  width: 33% !important;
}

.appendix .manufacturer_address {
  width: 50%;
}

.appendixHeader {
  display: flex;
  justify-content: space-between;
  font-family: "Frutiger_Ultra_bold", Times, serif;
}

.appendixHeader p {
  font-family: "Frutiger_Ultra_bold", Times, serif;
}

.appendixSection {
  padding: 10px;
  text-align: center;
  font-family: "Frutiger_Ultra_bold", Times, serif;
}

.appendixSection p {
  font-family: "Frutiger_Ultra_bold", Times, serif;
}

.appendixHeader2 {
  text-align: start;
  padding: 10px 0px;
  font-weight: bold;
  text-decoration: underline;
  font-family: "Frutiger_light", Times, serif;
}

.tableHeader {
  background-color: #d3d3d3;
  font-weight: bold;
  font-family: "Frutiger_light", Times, serif;
}

.appendix table,
.appendix th,
.appendix td {
  border: 1px solid;
  border-collapse: collapse;
  font-family: "Frutiger_light", Times, serif;
}

.tableB {
  width: 90%;
}

.tableB td {
  padding-left: 5px;
}

.appendix .tableCmodel,
.appendix_rm .tableCmodel_rm {
  width: 100%;
  border: 0px;
}

.appendix .tableCcomp,
.appendix_rm .tableCcomp_rm {
  width: 100%;
  border: 0px;
}

.tableCcomp td,
.tableCmodel td,
.tableCcomp th,
.tableCmodel th {
  border: 0px;
  width: 33.3%;
  text-align: left;
}

.tableCcomp_rm td,
.tableCmodel_rm td,
.tableCcomp_rm th,
.tableCmodel_rm th {
  border: 0px;
  width: 25%;
  text-align: left;
}

.romanIndex {
  padding-top: 10px;
  font-weight: bold;
  text-decoration: underline;
  font-family: "Frutiger_light", Times, serif;
}

.tableCcomp thead td,
.tableCmodel thead {
  font-weight: bold;
}
